@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3 {
  margin: 20px 0;
}

h1 {
  font-size: 48px;
  font-weight: 600;
}

h2 {
  font-size: 38px;
  font-weight: 600;
}

h3 {
  font-size: 24px;
  font-weight: 500;
}

p {
  margin: 10px 0;
}
